<template>
  <div class="cartSign">
    <div class="card_wrapper h-100">
      <div class="pagination">
        <div class="prev">
          <img src="../../assets/profile_assets/chevron-left.svg" alt="" />
        </div>
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div class="next">
          <img
            src="../../assets/profile_assets/cevron-right-orange.svg"
            alt=""
          />
        </div>
      </div>

      <div class="top_card">
        <router-link :to="{ name: 'home', params: { lang: 'uz' } }">
          <img src="@/assets/uzum-logo.svg" alt="" />
        </router-link>
        <p>
          {{ $localize('card-sign')['text'] }}
        </p>
        <h2>{{ $localize('card-sign')['title'] }}</h2>
      </div>

      <div class="form_input">
        <label class="form-label" for="inp2">
          {{ $localize('card-sign')['number'] }}
        </label>
        <!-- <input v-model="date" v-mask="11 / 11 / 1111" placeholder="Date" /> -->
        <input
          type="tel"
          v-model="cart"
          v-mask="'#### #### #### ####'"
          placeholder="0000 0000 0000 0000"
          ref="inputNumber"
          id="inp2"
          class="form-control"
        />
        <span v-if="cardValidation" style="color: red">{{ cardValidation }}</span>

      </div>

      <div class="form_input">
        <label class="form-label" for="inp1">
          {{ $localize('card-sign')['exp'] }}
        </label>
        <!-- <input v-model="date" v-mask="11 / 11 / 1111" placeholder="Date" /> -->
        <input
          placeholder="00/00"
          ref="inputNumber"
          v-model="cartDate"
          v-mask="'##/##'"
          type="tel"
          id="inp1"
          class="form-control"
        />
      </div>

      <div class="d-flex-item form-check">
        <div class="vs-checkbox-con">
          <input
            type="checkbox"
            id="1601"
            class="vs-checkbox"
            v-model="check"
          />
          <div class="vs-checkbox-mask">
            <i class="vs-icon-check">
              <span>
                <div class="line1"></div>
                <div class="line2"></div>
              </span>
            </i>
          </div>
        </div>
        <label for="1601">
          {{ $localize('card-sign')['agree-with'] }}
          <a target="_blank" href="https://paymart.uz/doc/publicOffer.pdf">
            {{ $localize('card-sign')['public-oferta'] }}
          </a>
        </label>
      </div>

      <router-link class="security_form d-flex" :to="$route.path">
        <div class="icon" @click="active = !active">
          <img src="../../assets/icons/security 1.svg" alt="" />
        </div>
        <span @click="active = !active">
          {{ $localize('card-sign')['politic-confidence'] }}
        </span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.50403 5.57031L15.934 12.0003L9.50403 18.4303"
            stroke="#6610F5"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </router-link>

      <div class="form_button">
        <button
          :disabled="loading"
          @click="cardAdd"
          :class="{ 'btn-loading': loading }"
          class="p-button"
          :data-disabled="!check || cardValidation"
        >
          {{ $localize('button')['continue'] }}
        </button>
      </div>

      <router-link class="security_form d-none-sm" :to="$route.path">
        <div class="icon" @click="active = !active">
          <img src="../../assets/icons/security 1.svg" alt="" />
        </div>
        <span @click="active = !active">
          {{ $localize('card-sign')['politic-confidence'] }}
        </span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.50403 5.57031L15.934 12.0003L9.50403 18.4303"
            stroke="#6610F5"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </router-link>
    </div>

    <div class="security_alert" v-if="active" ref="alert">
      <div class="overlay" @click="active = !active"></div>

      <div class="card">
        <h1>{{ $localize('card-sign')['politic-confidence'] }}</h1>

        <p>{{ $localize('oferta')['title'] }}</p>

        <h3>{{ $localize('oferta')['partners']['title'] }}</h3>

        <div class="card_img">
          <img src="../../assets/img/uzcard.svg" alt="" />
          <img src="../../assets/img/humo.svg" alt="" />
        </div>

        <p>{{ $localize('oferta')['partners']['text'] }}</p>

        <h3>{{ $localize('oferta')['send-data']['title'] }}</h3>

        <p>{{ $localize('oferta')['send-data']['text'] }}</p>

        <div class="card_img">
          <img src="../../assets/img/sslsert.svg" alt="" />
        </div>

        <div class="card_back" @click="active = !active">
          <p-button :to="{ name: 'cardAdd' }">{{ $localize('button')['back'] }}</p-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    loading: false,
    cart: '',
    cartDate: '',
    active: false,
    number: '',
    check: false,
  }),
  computed: {
    cardValidation() {
       if (this.cart !== null) {
        const cardBlogs = this.cart.split(' ');
        const lastTwoNumbers = cardBlogs[1]?.slice(2, 4);
        // Проверка на корпоративную карту
        if (cardBlogs[0] == '8600') {
          if (lastTwoNumbers === "32" || lastTwoNumbers === "08") {
            return this.$localize("error")["cardAdd"]["c_card_error"];
          }
        }
      }

      return false;
    },
  },
  methods: {
    async cardAdd() {
      if (this.check) {
        this.loading = true;

        if (this.cartDate && this.cart) {
          this.$toasted.clear();

          let cartDate = this.cartDate;
          let inpCartDate = Number(cartDate[3] + cartDate[4]);
          let inpCartMonth = Number(cartDate[0] + cartDate[1]);
          let date = new Date();
          let nowDateYear = +String(date.getFullYear()).slice(2);
          let nowMonth = date.getMonth() + 1;

          if (inpCartMonth > 12) {
            this.$toastError(this.$localize('error')['term']);
            this.loading = false;
          }

          if (inpCartDate > nowDateYear + 5 || inpCartDate < nowDateYear) {
            this.$toastError(this.$localize('error')['term']);
            this.loading = false;
          }

          if (inpCartDate === nowDateYear + 5 && inpCartMonth > nowMonth) {
            this.$toastError(this.$localize('error')['term']);
            this.loading = false;
          }

          if (inpCartDate === nowDateYear && inpCartMonth < nowMonth) {
            this.$toastError(this.$localize('error')['term']);
            this.loading = false;
          }

          if (
            !(inpCartMonth > 12) &&
            !(inpCartDate > nowDateYear + 5 || inpCartDate < nowDateYear) &&
            !(inpCartDate === nowDateYear + 5 && inpCartMonth > nowMonth) &&
            !(inpCartDate === nowDateYear && inpCartMonth < nowMonth)
          ) {
            let cardDate = this.cartDate.replace(/[^0-9]/g, '');
            localStorage.setItem('card_number', this.cart);
            localStorage.setItem('card_valid_date', cardDate);

            const res = await this.$axios
              .post('buyer/send-sms-code-uz', {
                card: this.cart,
                exp: cardDate,
              })
              .catch((e) => {
                this.$toastError(e.message);
              });

            this.loading = false;

            if (res.data.status === 'success') {
              localStorage.setItem('card_hash', res.data.hash);
              await this.$router.push({ name: 'sign_sms_code' });
              this.loading = false;
            }

            if (res.data.status === 'error') {
              if(res.data.message) {
                this.$toastError(res.data.message)
              } else if (res.data.info) {
                let errorMessage = 'undefined_error';
                switch (res.data.info) {
                  case 'empty_balance': errorMessage = 'empty_card';
                    break;
                  case 'error_scoring': errorMessage = 'error_scoring';
                    break;
                  case 'error_card_exp': errorMessage = 'term';
                    break;
                  case 'service_unavailable': errorMessage = 'serviceUnavailable';
                    break;
                  case 'error_phone_not_equals': errorMessage = 'sms-check';
                    break;
                  case "error_card_equal": errorMessage = 'card-has';
                    break;
                  case "error_card_sms_off": errorMessage = 'error_card_sms_off';
                    break;
                  default: Array.isArray(res.data.info) ? this.$toastError(res.data.info[0]) : this.$toastError(res.data.info);
                }
                this.$toastError(this.$localize('error')[errorMessage])
              }
              if (res.response) {
                if (res.response.errors[0] === ('Name is not set')) {
                  this.$toastError(this.$localize('error')['empty-name']);
                }
              }
            }
            if (res.data.status === 'error_card_scoring') {
              this.$toastError(this.$localize('error')['enough-money']);
            }
          }
        } else {
          this.$toastError(this.$localize('error')['empty']);
          this.loading = false;
        }
      }
    },
  },
  async created() {
    this.number = localStorage.getItem('number');
    this.cart = localStorage.getItem('card_number');
    this.cartDate = localStorage.getItem('card_valid_date');

    await this.$store.dispatch('buyers/userStatus');
  },
};
</script>

<style lang="scss" scoped>
  .cartSign {
    width: 100%;
    padding-top: 17%;
    height: 100%;
    background: #f5f5f5;

    .card_wrapper {
      // max-width: 500px;
      margin: auto;
    }

    .top_card {
      padding-left: 12px;

      p {
        margin-top: 11px;
        margin-bottom: 56px;
      }

      h2 {
        font-weight: 900;
        font-size: 40px;
        line-height: 40px;
        color: $black;
        margin-bottom: 32px;
      }
    }

    .form_input {
      margin-bottom: 16px;
      width: 100%;
      max-width: 400px;
      padding: 0 12px;

      .form-label {
        margin-bottom: 4px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: $black;
      }

      .form-control {
        width: 100%;
        padding: 16px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: $black;
        box-sizing: border-box;
        background: $white;
        border-radius: 8px;
        border: none;
        transition: 0.4s;

        &::placeholder {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: rgba(30, 30, 30, 0.3);
        }

        &:focus {
          border: 1px solid #6610F5;
          outline: none;
          color: rgb(30, 30, 30);
        }
      }

      small {
        color: red;
      }
    }

    .form_button {
      margin-top: 40px;
      max-width: 400px;
      padding-left: 12px;
      padding-right: 12px;
    }

    .security_form {
      display: flex;
      align-items: center;
      margin-top: 32px;
      padding-left: 12px;

      .icon {
        background: $main;
        border-radius: 8px;
        padding: 4px;
        display: flex;
        align-items: center;
      }

      span {
        margin-left: 12px;
        color: $main;
      }
    }
  }

  button.p-button {
    cursor: pointer;
    background: $main;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    border-radius: 14px;
    width: 100%;
    padding: 16px 24px;
    font-weight: 600;
    font-size: 15px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    transition: 0.2s;
    border: none;

    &:hover {
      background: $main-light;
    }
  }

  .btn-loading {
    position: relative;
    pointer-events: none;
    color: transparent !important;

    &:after {
      animation: spinAround 500ms infinite linear;
      border: 2px solid $white;
      border-radius: 50%;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
    }
  }

  button {
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    height: 100%;

    &.btn-loading {
      cursor: not-allowed;
    }

    &[data-disabled] {
      background: #d8d8d8;
      color: $white;
      cursor: not-allowed;
      box-shadow: none !important;

      &:hover {
        background: #d8d8d8;
        color: $white;
        cursor: not-allowed;
        box-shadow: none !important;
      }
    }
  }

  .security_alert {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.3);
    backdrop-filter: blur(12px);
    overflow: auto;
    padding: 50px;
    z-index: 1001;

    .overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $main;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .card {
      padding: 40px;
      background: white;
      max-width: 820px;
      margin: auto;
      position: relative;
      z-index: 6;
      top: 0;

      h1 {
        font-weight: 900;
        font-size: 40px;
        line-height: 40px;
        color: $black;
        margin-bottom: 16px;
      }

      h3 {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: $black;

        &:first-child {
          margin-bottom: 16px;
        }

        &:last-child {
          margin-bottom: 8px;
        }
      }

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: $black;
        margin-bottom: 32px;
        max-width: 590px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      &_back {
        max-width: 385px;
        margin-top: 32px;
      }
    }
  }

  @media (max-width: 1199px) {
    .cartSign {
      height: 100vh;
      text-align: center;
      padding-top: 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      .card_wrapper {
        margin: 0px;
      }

      .form_input {
        margin: 0px auto 16px auto;
        text-align: left;
      }

      .form_button {
        margin: 40px auto 16px auto;
      }

      .security_form {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }

  .pagination {
    display: none;
  }

  .d-flex {
    display: none !important;
  }

  .d-flex-item {
    display: flex !important;
  }

  .form-check {
    align-items: center;
    padding-left: 12px;

    label {
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.078px;
      color: #333333;
      margin-left: 8px;

      a {
        color: $main;
      }
    }
  }

  .vs-checkbox-con {
    min-width: 23px;
    height: 23px;
    border-radius: 9px;
    position: relative;
    z-index: 1;

    .vs-checkbox {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
      z-index: 100;
      cursor: pointer;
    }

    .vs-checkbox:checked ~ .vs-checkbox-mask {
      border: none;
    }

    .vs-checkbox:checked ~ .vs-checkbox-mask:before {
      opacity: 0;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    .vs-checkbox:checked ~ .vs-checkbox-mask:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    .vs-checkbox:checked ~ .vs-checkbox-mask .vs-icon-check {
      opacity: 1;
    }

    .vs-checkbox:checked ~ .vs-checkbox-mask .vs-icon-check span .line1:after {
      width: 100%;
      -webkit-transition: all 0.25s ease 0.1s;
      transition: all 0.25s ease 0.1s;
    }

    .vs-checkbox:checked ~ .vs-checkbox-mask .vs-icon-check span .line2:after {
      -webkit-transition: all 0.2s ease 0.3s;
      transition: all 0.2s ease 0.3s;
      height: 100%;
    }

    .vs-checkbox-mask .vs-icon-check {
      opacity: 0;
      z-index: 200;
    }

    .vs-icon-check {
      width: 23px;
      height: 23px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
      border-radius: inherit;

      span {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        display: block;
        position: relative;
        width: 8px;
        height: 13px;
        margin-top: -4px;

        .line1 {
          background: transparent;
          content: '';
          position: absolute;
          height: 2px;
          border-radius: 2px;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
          border-radius: 5px;
          z-index: 100;
          width: 8px;
          bottom: 0;

          &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 2px;
            background: $white;
            -webkit-transition: all 0.25s ease;
            transition: all 0.25s ease;
            border-radius: 5px 0 0 5px;
          }
        }

        .line2 {
          bottom: 0;
          right: 0;
          z-index: 100;
          background: transparent;
          content: '';
          position: absolute;
          height: 13px;
          border-radius: 5px;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
          width: 2px;

          &::after {
            content: '';
            position: absolute;
            width: 2px;
            height: 0%;
            background: $white;
            -webkit-transition: all 0.25s ease;
            transition: all 0.25s ease;
            bottom: 0;
            border-radius: 5px 5px 0 0;
          }
        }
      }
    }

    .vs-checkbox-mask {
      border: 1px solid #333333;
      border-radius: 32%;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 50;
      cursor: pointer;
      pointer-events: none;
      -webkit-box-sizing: border-box;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      z-index: -1;
      box-sizing: border-box;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      &::before {
        border-radius: inherit;
        -webkit-transition: all 0.25s ease;
        transition: all 0.25s ease;
        border: 2px solid rgba(var(--vs-gray-4), 1);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      &::after {
        background: $main;
        border-radius: inherit;
        transition: all 0.25s ease;
      }
    }

    .vs-checkbox-mask::after {
      opacity: 0;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
  }

  @media (max-width: 575px) {
    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 18px;
      margin-top: 0px;

      .prev {
        transform: rotate(180deg);
        display: flex;
        align-items: center;
      }

      .next {
        display: flex;
        align-items: center;
      }

      ul {
        display: flex;
        align-items: center;
        position: relative;

        li {
          width: 12px;
          height: 12px;
          background: $main;
          border-radius: 50%;
          margin: 0px 8px;
          position: relative;
          z-index: 2;

          &:first-child {
            width: 8px;
            height: 8px;
            background: $main;
            border-radius: 50%;
          }

          &:last-child {
            width: 8px;
            height: 8px;
            background: #a5a5a5;
            border-radius: 50%;
          }
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% - 16px);
          height: 1px;
          background: linear-gradient(to right, #6610F5 50%, #a5a5a5 50%);
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .d-flex {
      display: flex !important;
    }
    .d-flex-item {
      display: flex !important;
    }
    .form-check {
      align-items: center;
      padding-left: 12px;
      margin-bottom: 1rem;

      label {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.078px;
        color: #333333;
        margin-left: 8px;

        span {
          color: $main;
        }
      }
    }
    .vs-checkbox-con {
      min-width: 23px;
      height: 23px;
      border-radius: 9px;
      position: relative;
      z-index: 1;

      .vs-checkbox {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
        z-index: 100;
        cursor: pointer;
      }

      .vs-checkbox:checked ~ .vs-checkbox-mask {
        border: none;
      }

      .vs-checkbox:checked ~ .vs-checkbox-mask:before {
        opacity: 0;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
      }

      .vs-checkbox:checked ~ .vs-checkbox-mask:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }

      .vs-checkbox:checked ~ .vs-checkbox-mask .vs-icon-check {
        opacity: 1;
      }

      .vs-checkbox:checked ~ .vs-checkbox-mask .vs-icon-check span .line1:after {
        width: 100%;
        -webkit-transition: all 0.25s ease 0.1s;
        transition: all 0.25s ease 0.1s;
      }

      .vs-checkbox:checked ~ .vs-checkbox-mask .vs-icon-check span .line2:after {
        -webkit-transition: all 0.2s ease 0.3s;
        transition: all 0.2s ease 0.3s;
        height: 100%;
      }

      .vs-checkbox-mask .vs-icon-check {
        opacity: 0;
        z-index: 200;
      }

      .vs-icon-check {
        width: 23px;
        height: 23px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-transition: all 0.25s ease;
        transition: all 0.25s ease;
        border-radius: inherit;

        span {
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          display: block;
          position: relative;
          width: 8px;
          height: 13px;
          margin-top: -4px;

          .line1 {
            background: transparent;
            content: '';
            position: absolute;
            height: 2px;
            border-radius: 2px;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            border-radius: 5px;
            z-index: 100;
            width: 8px;
            left: -3px;
            bottom: 0;

            &::after {
              content: '';
              position: absolute;
              width: 0;
              height: 2px;
              background: $white;
              -webkit-transition: all 0.25s ease;
              transition: all 0.25s ease;
              border-radius: 5px 0 0 5px;
            }
          }

          .line2 {
            bottom: 0;
            right: 0;
            z-index: 100;
            background: transparent;
            content: '';
            position: absolute;
            height: 13px;
            border-radius: 5px;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            width: 2px;

            &::after {
              content: '';
              position: absolute;
              width: 2px;
              height: 0%;
              background: $white;
              -webkit-transition: all 0.25s ease;
              transition: all 0.25s ease;
              bottom: 0;
              border-radius: 5px 5px 0 0;
            }
          }
        }
      }

      .vs-checkbox-mask {
        border: 1px solid #333333;
        border-radius: 32%;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 50;
        cursor: pointer;
        pointer-events: none;
        -webkit-box-sizing: border-box;
        -webkit-transition: all 0.25s ease;
        transition: all 0.25s ease;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        z-index: -1;
        box-sizing: border-box;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }

        &::before {
          border-radius: inherit;
          -webkit-transition: all 0.25s ease;
          transition: all 0.25s ease;
          border: 2px solid rgba(var(--vs-gray-4), 1);
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
        }

        &::after {
          background: $main;
          border-radius: inherit;
          transition: all 0.25s ease;
        }
      }

      .vs-checkbox-mask::after {
        opacity: 0;
        -webkit-transition: all 0.25s ease;
        transition: all 0.25s ease;
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
      }
    }
    .cartSign {
      padding-top: 30px;
      padding-bottom: 100px;

      .card_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /*padding-bottom: 24px;*/
      }

      .form_input .form-label {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: $black;
      }

      .top_card h2 {
        font-weight: 900;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 4px;
      }

      .security_form span {
        font-size: 14px;
        text-align: left;
      }

      .top_card p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.24px;
        margin-top: 0px;
        margin-bottom: 16px;
      }

      .top_card {
        padding-left: 12px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        img {
          display: none;
        }
      }

      .form_input .form-control {
        padding: 10px 16px;
        font-size: 14px;
        line-height: 20px;

        &::placeholder {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .security_form {
        margin-top: 8px;
      }

      .form_button {
        margin: auto 0px 32px;
        max-width: 100%;
      }
    }
    .security_alert {
      padding: 0px;
      text-align: left;

      .card {
        padding: 27px 16px;

        img {
          width: 45%;
        }

        h1 {
          font-weight: bold;
          font-size: 24px;
          line-height: 28px;
          letter-spacing: 0.326451px;
        }

        p {
          font-size: 13px;
          line-height: 18px;
          letter-spacing: 0.02em;
          color: $black;
        }

        h3 {
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          color: $black;
        }
      }

      .card_back {
        max-width: 100%;
      }
    }
    .h-100 {
      height: 100%;
    }
    .d-none-sm {
      display: none !important;
    }
  }

  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
</style>
